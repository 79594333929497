var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("labels.log_system"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("labels.close"))+" ")])],1),_c('v-card-text',[_c('div',[_c('v-simple-table',{staticClass:"table-padding-2",attrs:{"fixed-header":"","height":"calc(100vh - 250px)"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:`${index}_${item.id}`},[_c('td',{staticStyle:{"vertical-align":"top"}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("labels.time"))+":")]),_vm._v(" "+_vm._s(_vm.formatDateTime(item.last_update, "HH:mm:ss DD/MM/YYYY"))+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("labels.status"))+":")]),_vm._v(" "+_vm._s(_vm.stateName[item.state])+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("labels.employee_last_change"))+":")]),_vm._v(" "+_vm._s(item.employee_changed_name)+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t("labels.user_change"))+":")]),_vm._v(" "+_vm._s(item.identity_changed_name)+" ")])]),_c('td',_vm._l((item),function(val,key){return _c('div',{key:`${val}_${key}_${item._id}`},[(
                      (!_vm.items[index + 1] || val !== _vm.items[index + 1][key]) &&
                      ![
                        '_id',
                        'id',
                        'employee_changed_name',
                        'identity_changed_name',
                      ].includes(key)
                    )?_c('div',{staticClass:"my-1"},[_c('v-chip',{staticClass:"ml-0 mr-2 black--text",attrs:{"color":"orange lighten-4","label":"","small":""}},[_vm._v(_vm._s(key))]),(!_vm.items[index + 1] || !_vm.items[index + 1][key])?_c('span',{staticClass:"grey--text font-italic"},[_vm._v("N/A")]):_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.items[index + 1][key]))]),_vm._v(" → "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(val))])],1):_vm._e()])}),0)])}),0)]},proxy:true}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }